import React, { useState, useEffect } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import TextField from "@mui/material/TextField";

interface CustomToggleButtonGroupProps {
	value: number | undefined | null;
	onChange: (value: number | null) => void;
	options: Array<{
		value: number | string | null;
		label: string;
		disabled?: boolean;
	}>;
	isInput?: boolean;
	exclusive?: boolean;
	size?: "small" | "medium" | "large";
	orientation?: "horizontal" | "vertical";
}

export const CustomToggleButtonGroup: React.FC<
	CustomToggleButtonGroupProps
> = ({
	value,
	onChange,
	options,
	exclusive = true,
	size = "medium",
	orientation = "horizontal",
	isInput,
}) => {
	const [toggleValue, setToggleValue] = useState<number | null>(null);
	const [inputValue, setInputValue] = useState<string>("");

	useEffect(() => {
		setToggleValue(value ?? null);
		if (value && !options.some((option) => option.value === value)) {
			setInputValue(value.toString());
		}
	}, [value]);

	const handleChange = (e: React.MouseEvent<HTMLElement>) => {
		const target = e.target as HTMLButtonElement;
		const value = target.value;

		if (value === "" || isNaN(Number(value))) {
			setToggleValue(null);
			onChange(null);
		} else {
			const numValue = Number(value);
			setToggleValue(numValue);
			onChange(numValue);
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const rawValue = e.target.value.replace(",", ".");

		if (rawValue === "") {
			setToggleValue(null);
			onChange(null);
		}

		if (!/^-?\d*\.?\d*$/.test(rawValue)) {
			return;
		}
		setInputValue(rawValue);
	};

	return (
		<div>
			<ToggleButtonGroup
				value={toggleValue}
				exclusive={exclusive}
				onChange={(e) => handleChange(e)}
				size={size}
				orientation={orientation}
			>
				{options.map((option) => (
					<ToggleButton
						key={option.value}
						value={option.value ?? null!}
					>
						{option.label}
					</ToggleButton>
				))}
				{isInput && (
					<ToggleButton
						key={"input"}
						value={Number(inputValue)}
						style={{
							pointerEvents: "auto",
						}}
					>
						<TextField
							size="small"
							value={inputValue}
							onChange={(e) =>
								handleInputChange(
									e as React.ChangeEvent<HTMLInputElement>
								)
							}
							variant="standard"
							placeholder="TP"
							sx={{
								width: "40px",
								"& input": {
									textAlign: "center",
									padding: "0",
									fontFamily:
										"'Roboto', 'Helvetica', 'Arial', sans-serif",
									fontSize: "0.875rem",
									fontWeight: 500,
									height: "1.75rem",
									lineHeight: "1.75rem",
									color:
										Number(inputValue) == toggleValue
											? "black"
											: "gray",
								},
								"& .MuiInputBase-root": {
									textAlign: "center",
								},
								margin: "0px",
							}}
						/>
					</ToggleButton>
				)}
			</ToggleButtonGroup>
		</div>
	);
};

export default CustomToggleButtonGroup;
