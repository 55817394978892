import { useEffect, useState } from "react";
import {
	FileWithShare,
	JobItemEntityType,
} from "../../../../../lib/supabase/supabaseTypes";
import {
	Button,
	Chip,
	IconButton,
	LinearProgress,
	Tooltip,
} from "@mui/material";
import { JobItemsTable } from "./job-document-components/job-items-table/job-items-table.component";
import { JobDocumentTitle } from "./job-document-components/job-document-title.component";
import {
	JobDocumentTypeEnum,
	MapJobDocumentTypeToColor,
} from "./job-document.types";
import { JobStatusEnum } from "@/lib/types/job";
import { CustomDatePicker } from "@/dentlab/src/components/custom-date-picker/custom-date-picker.component";
import CalculateIcon from "@mui/icons-material/Calculate";
import { TotalSidebar } from "./job-document-components/total-sidebar.component";
import {
	PdfTemplateType,
	XmlTemplateType,
} from "@/dentlab/src/pdf-templates/document-template.types";
import { useJobDocumentActions } from "@/dentlab/src/hooks/actions/useJobDocumentActions";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { JobDocumentOptionsMenu } from "./job-document-components/job-document-options-menu.component";
import { useJobStore } from "@/dentlab/src/store/Jobs";
import { FileDropDown } from "@/dentlab/src/components/file-display-components/file-dropdown.component";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { calculateJobTotalsLegacy } from "@/dentlab/src/lib/utils/calculate";
import { useHandleExportDocument } from "@/dentlab/src/hooks/useHandleExportDocument/useHandleExportDocument";
import { JobDocumentWithFiles } from "@/dentlab/src/store/Jobs/job-documents.store";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
	Calculator,
	ClipboardList,
	ClipboardPaste,
	ClipboardPlus,
} from "lucide-react";

const getBorderColorTop = (
	isVisible: boolean,
	jobDocumentType: JobDocumentTypeEnum
) => {
	const color = MapJobDocumentTypeToColor[jobDocumentType] ?? "#000000";
	return isVisible
		? color
		: `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(
				color.slice(3, 5),
				16
			)}, ${parseInt(color.slice(5, 7), 16)}, 0.3)`;
};

const getBorderColorOthers = (isVisible: boolean) => {
	return isVisible ? "#E0E0E0" : "rgba(224, 224, 224, 0.3)";
};

export interface JobDocumentExportOption {
	label: string;
	type: PdfTemplateType;
}

/**
 * JobDocument - Component displaying information for a single job document
 * @param document - job document object to display, fetched from the database by the parent component
 * @param clientId - id of the client the document belongs to
 *
 * State this component has that is relevant for exporting the document:
 * - jobItems: JobItemEntityType[] - all job items that are associated with the document
 */
export const JobDocument: React.FC<{
	jobDocument: JobDocumentWithFiles;
}> = ({ jobDocument }) => {
	const { jobDocumentLoading, handleExportDocument } =
		useHandleExportDocument();
	const { debugMode, client } = useCentralStore((state) => ({
		debugMode: state.debugMode,
		client: state.client,
	}));
	const {
		jobItemsForDocuments,
		fetchJobItems,
		job,
		fetchJobDocument,
		updateJobDocumentDate,
	} = useJobStore((state) => ({
		jobItemsForDocuments: state.jobItemsForDocuments,
		fetchJobItems: state.fetchJobItems,
		job: state.job,
		fetchJobDocument: state.fetchJobDocument,
		updateJobDocumentDate: state.updateJobDocumentDate,
	}));

	const [jobTotalSidebarOpen, setJobTotalSidebarOpen] = useState(false);
	const { organization } = useCentralStore((state) => ({
		organization: state.organization,
	}));
	const [isVisible, setIsVisible] = useState(true);
	const jobDocumentType = jobDocument.type as JobDocumentTypeEnum;
	const jobItems = jobItemsForDocuments?.[jobDocument.id] ?? [];

	useEffect(() => {
		fetchJobItems(jobDocument.id);
	}, []);

	const handleCloseJobTotalSidebar = () => {
		setJobTotalSidebarOpen(false);
	};
	const [files, setFiles] = useState<FileWithShare[]>([]);

	useEffect(() => {
		const sortedFiles = [...(jobDocument?.files_with_shares ?? [])].sort(
			(a, b) => {
				const dateA = new Date(a.created_at ?? "");
				const dateB = new Date(b.created_at ?? "");
				return dateB.getTime() - dateA.getTime();
			}
		);
		setFiles(sortedFiles);
	}, [jobDocument?.files_with_shares]);

	const updateFile = (file: FileWithShare) => {
		setFiles((prev) =>
			prev.map((p) => {
				if (p.id == file.id) return file;
				else return p;
			})
		);
	};

	const loading = jobDocumentLoading && jobDocumentLoading === jobDocument.id;
	const disabled = jobDocument.status !== JobStatusEnum.IN_PROGRESS;

	if (!organization) {
		return null;
	}
	if (!client) {
		return null;
	}

	const { total } = calculateJobTotalsLegacy({
		job,
		jobDocument,
		jobItems,
		organization,
		client,
	});

	return (
		<>
			{jobTotalSidebarOpen && job && (
				<TotalSidebar
					jobItems={jobItems as JobItemEntityType[]}
					job={job}
					onClose={handleCloseJobTotalSidebar}
					jobDocument={jobDocument}
				/>
			)}
			<div className="m-1">
				<div
					style={{
						height: "10px",
						backgroundColor: getBorderColorTop(
							isVisible,
							jobDocumentType
						),
						borderRadius: "10px 10px 0 0",
					}}
				/>
				<div
					style={{
						borderBottom: `4px solid ${getBorderColorOthers(
							isVisible
						)}`,
						borderRight: `4px solid ${getBorderColorOthers(isVisible)}`,
						borderLeft: `4px solid ${getBorderColorOthers(isVisible)}`,
						borderRadius: "0px 0px 10px 10px",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "space-between",
							padding: "4px",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<JobDocumentIcon
								open={isVisible}
								onClick={() => setIsVisible(!isVisible)}
								type={
									jobDocument.type as JobDocumentTypeEnum | null
								}
								color={getBorderColorTop(
									isVisible,
									jobDocumentType
								)}
							/>
							<JobDocumentTitle
								title={jobDocument.title}
								id={jobDocument.id}
								status={jobDocument.status}
							/>
							{jobDocument.type !==
								JobDocumentTypeEnum.MATERIALS && (
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										paddingLeft: "10px",
									}}
								>
									<CustomDatePicker
										initialValue={
											jobDocument.date || new Date()
										}
										onChange={(value) =>
											updateJobDocumentDate(
												jobDocument.id,
												jobDocument.status,
												value ?? ""
											)
										}
									/>
								</div>
							)}
							{jobDocument.type !==
								JobDocumentTypeEnum.MATERIALS && (
								<JobDocumentStatusChip
									status={jobDocument.status}
								/>
							)}
						</div>
						{jobDocument.type !== JobDocumentTypeEnum.MATERIALS && (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									paddingRight: "10px",
									alignItems: "center",
								}}
							>
								<CompleteAndExportButton
									jobDocument={jobDocument}
									jobItems={jobItems ?? []}
									onExport={(
										type: PdfTemplateType | XmlTemplateType,
										jobItems: JobItemEntityType[]
									) =>
										handleExportDocument(
											type,
											jobItems,
											jobDocument
										)
									}
								/>
								<Button
									onClick={() => setJobTotalSidebarOpen(true)}
									startIcon={<CalculateIcon />}
								>
									{total.value === 0 ? "0.00" : total.text}
								</Button>
								<JobDocumentOptionsMenu
									jobDocument={jobDocument}
									jobItems={jobItems ?? []}
									onExport={(
										type: PdfTemplateType | XmlTemplateType
									) =>
										handleExportDocument(
											type,
											jobItems ?? [],
											jobDocument
										)
									}
								/>
							</div>
						)}
					</div>
					{loading && <LinearProgress />}
					{files?.length > 0 && (
						<div
							data-testid="exported-pdfs"
							style={{
								display: "flex",
								borderTop: `1.5px solid ${getBorderColorOthers(
									isVisible
								)}`,
								padding: "3px 6px",
								overflowX: "auto",
							}}
						>
							{files?.map((file) => (
								<FileDropDown
									key={file.id}
									file={file}
									updateFile={updateFile}
									job={job ?? undefined}
								/>
							))}
						</div>
					)}
					{isVisible && (
						<JobItemsTable
							jobDocumentType={
								jobDocument.type as JobDocumentTypeEnum
							}
							jobDocumentId={jobDocument.id}
							status={jobDocument.status}
							disabled={disabled}
						/>
					)}
					{debugMode && (
						<div>
							ID: {jobDocument.id} Type: {jobDocument.type}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

const JobDocumentStatusChip: React.FC<{
	status: JobStatusEnum;
}> = ({ status }) => {
	// switch label based on status
	let label = "";
	switch (status) {
		case JobStatusEnum.IN_PROGRESS:
			label = "Pendent";
			break;
		case JobStatusEnum.COMPLETED:
			label = "Abgeschlossen";
			break;
		case JobStatusEnum.ARCHIVED:
			label = "Archiviert";
			break;
		case JobStatusEnum.BOOKED_SINGLE:
			label = "Verbucht (Einzelrechnung)";
			break;
		case JobStatusEnum.BOOKED_MONTHLY:
			label = "Verbucht (Sammelrechnung)";
			break;
		default:
			label = "Unbekannt";
	}

	return (
		<Chip
			label={label}
			size="small"
			sx={{
				color: "var(--color-white)",
				textTransform: "uppercase",
				fontSize: "12px",
				borderRadius: "5px",
				padding: "2px 4px",
				marginLeft: "10px",
			}}
		/>
	);
};

const CompleteAndExportButton: React.FC<{
	jobDocument: JobDocumentWithFiles;
	jobItems: JobItemEntityType[];
	onExport: (type: PdfTemplateType, jobItems: JobItemEntityType[]) => void;
}> = ({ jobDocument, jobItems, onExport }) => {
	const { isExportJobDocumentAllowed } = useJobDocumentActions();
	const fetchJob = useJobStore((state) => state.fetchJob);
	const { isAllowed, explanation } = isExportJobDocumentAllowed(
		jobDocument,
		jobItems
	);

	const handleCompletionClick = () => {
		if (jobDocument.type === JobDocumentTypeEnum.Quotation) {
			onExport(PdfTemplateType.KOSTENVORANSCHLAG, jobItems);
		} else if (jobDocument.type === JobDocumentTypeEnum.DeliveryNote) {
			onExport(PdfTemplateType.LIEFERSCHEIN, jobItems);
		} else if (jobDocument.type === JobDocumentTypeEnum.CreditNote) {
			onExport(PdfTemplateType.GUTSCHRIFT, jobItems);
		}
	};

	let tooltipText = "Abschliessen und exportieren";
	let icon = <CheckCircleOutlineIcon color="primary" />;
	if (jobDocument.status === JobStatusEnum.COMPLETED) {
		icon = <CheckCircleIcon color="success" />;
		tooltipText = "Abgeschlossen";
	} else if (!isAllowed) {
		icon = <CheckCircleOutlineIcon color="disabled" />;
		tooltipText = explanation;
	}

	return (
		<Tooltip title={tooltipText} placement="bottom">
			{/* We wrap IconButton in a span tag to allow tooltip when disabled */}
			<span>
				<IconButton
					onClick={async () => {
						handleCompletionClick();
					}}
					disabled={!isAllowed}
					data-testid="complete-job-document-button"
					aria-label="Button to export job document and setting its status to completed"
				>
					{icon}
				</IconButton>
			</span>
		</Tooltip>
	);
};

export const JobDocumentIcon: React.FC<{
	open: boolean;
	onClick: () => void;
	type: JobDocumentTypeEnum | null;
	color: string;
}> = ({ open, onClick, type, color }) => {
	let icon = open ? (
		<ArrowDropDownIcon fontSize="small" />
	) : (
		<ArrowRightIcon fontSize="small" />
	);
	if (type === JobDocumentTypeEnum.Quotation) {
		icon = <Calculator fontSize="small" color={open ? color : "gray"} />;
	} else if (type === JobDocumentTypeEnum.DeliveryNote) {
		icon = (
			<ClipboardPaste fontSize="small" color={open ? color : "gray"} />
		);
	} else if (type === JobDocumentTypeEnum.CreditNote) {
		icon = <ClipboardPlus fontSize="small" color={open ? color : "gray"} />;
	} else if (type === JobDocumentTypeEnum.MATERIALS) {
		icon = <ClipboardList fontSize="small" color={open ? color : "gray"} />;
	}

	return (
		<IconButton aria-label="open" size="small" onClick={onClick}>
			{icon}
		</IconButton>
	);
};
