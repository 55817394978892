import { StateCreator } from "zustand";
import { formatArticle, formatTariff } from "./utils";
import { Logger } from "@/lib/logger/Logger";
import { useCentralStore } from "../Central";
import { supabase } from "@/lib/supabase";
import {
	JobItemEntityType,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { produce } from "immer";
import { v4 as uuidv4 } from "uuid";
import { showNotification } from "../Central/selectors";
import { ExtendedArticleEntityType, ExtendedTariffEntityType } from "./types";
import { JobStoreUnion } from ".";
import { JobStatusEnum } from "@/lib/types/job";
import { JobItemTypeEnum } from "@/lib/supabase/supabaseEnums";
interface JobItemState {
	jobItemsForDocuments: Record<string, JobItemEntityType[]>;
	timers: Record<number, NodeJS.Timeout>;
}

export const initialJobItemState: JobItemState = {
	timers: {},
	jobItemsForDocuments: {},
};

export interface JobItemSlice extends JobItemState {
	fetchJobItems: (jobDocumentId: number) => Promise<void>;
	addItem: (
		code: string,
		jobDocumentId: number,
		itemType: "article" | "tariff",
		options?: { force: boolean }
	) => Promise<void>;
	addTariff: (
		code: string,
		jobDocumentId: number,
		options?: { force: boolean }
	) => Promise<void>;
	addArticle: (
		code: string,
		jobDocumentId: number,
		options?: { force: boolean }
	) => Promise<void>;
	deleteJobItems: (
		jobItemIds: string[],
		jobDocumentId: number
	) => Promise<void>;
	pasteJobItems: (
		jobItems: JobItemEntityType[],
		jobDocumentId: number
	) => Promise<void>;
	changeJobItem: (jobItem: any) => Promise<void>;
	upsertJobItems: (
		jobDocumentId: number,
		errorMessage: string
	) => Promise<void>;
	handleTimer: (
		jobDocumentId: number,
		itemType: "tariff" | "article" | "group"
	) => Promise<void>;
	updateJobItems: (jobDocumentId: number, tp_value: number) => Promise<void>;
	changeTariffInRow: (
		code: string,
		jobItem: JobItemEntityType,
		type: "article" | "tariff"
	) => Promise<{ error: boolean; message: string }>;
}

export const createJobItemStore: StateCreator<
	JobStoreUnion,
	[],
	[],
	JobItemSlice
> = (set, get) => ({
	...initialJobItemState,
	fetchJobItems: async (jobDocumentId: number) => {
		const { data, error } = await supabase
			.from(SupabaseTableEnum.JOB_ITEMS)
			.select(`*`)
			.eq("job_document_id", jobDocumentId)
			.order("code", { ascending: true });

		if (error) {
			showNotification({
				message: "Fehler beim Laden der Positionen",
				type: "error",
			});
			Logger.error(error);
			return;
		}

		set({
			jobItemsForDocuments: {
				...get().jobItemsForDocuments,
				[jobDocumentId]: data,
			},
		});
	},

	// Post since this is explicitly for updating the job items through the network and not the local store
	upsertJobItems: async (jobDocumentId: number, errorMessage: string) => {
		const items = get().jobItemsForDocuments[jobDocumentId].map((i) => {
			const {
				// @ts-expect-error unregistered properties
				interpr_de,
				// @ts-expect-error unregistered properties
				interpr_fr,
				// @ts-expect-error unregistered properties
				interpr_it,
				fpo_id,
				// @ts-expect-error unregistered properties
				bpo_id,
				...rest
			} = i;
			return {
				...rest,
			};
		});

		const { error } = await supabase
			.from(SupabaseTableEnum.JOB_ITEMS)
			.upsert(items, { onConflict: "id", defaultToNull: false });

		if (error) {
			showNotification({
				message: "Fehler beim Speichern der Positionen",
				type: "error",
			});
			Logger.error(error, {}, errorMessage);

			// it would be better to remove the items from the store here
			// for now we re-fetch the job items
			get().fetchJobItems(jobDocumentId);

			return;
		}
	},

	addItem: async (
		code: string,
		jobDocumentId: number,
		itemType: "article" | "tariff",
		options?: { force: boolean }
	) => {
		const existingItems: number[] = [];
		get().jobItemsForDocuments[jobDocumentId]?.forEach((item, i) => {
			if (item.code_e?.toLowerCase() === code.toLowerCase()) {
				existingItems.push(i);
			}
		});

		if (existingItems.length > 0 && !options?.force) {
			set(
				produce((state) => {
					existingItems.forEach((i) => {
						state.jobItemsForDocuments[jobDocumentId][i].quantity +=
							1;
					});
				})
			);
		} else {
			let items:
				| Record<string, ExtendedTariffEntityType>
				| Record<string, ExtendedArticleEntityType>
				| null = null;
			if (itemType == "tariff")
				items = useCentralStore.getState().tariffs;
			else if (itemType == "article")
				items = useCentralStore.getState().articles;

			if (!items) {
				showNotification({
					message:
						"Position kann nicht hinzugefügt werden. Fehlende Stammdaten.",
					type: "error",
				});
				return;
			}
			let item = items[code];
			// Try case insensitive search if uppercase is not found
			if (!item) {
				const itemIndex = Object.keys(items).findIndex(
					(key) => key.toLowerCase() === code.toLowerCase()
				);
				if (itemIndex !== -1) {
					item = items[Object.keys(items)[itemIndex]];
				}
			}
			if (!item) {
				showNotification({
					message: "Position nicht gefunden.",
					type: "error",
				});
				return;
			}

			const clientId = useCentralStore.getState().clientId;

			if (!jobDocumentId || !clientId) {
				showNotification({
					message:
						"Position kann nicht hinzugefügt werden. Fehlende Referenz.",
					type: "error",
				});
				Logger.error("Job document id or client id is not set");
				return;
			}

			const job = get().job;
			if (!job) {
				showNotification({
					message:
						"Position kann nicht hinzugefügt werden. Fehlender Auftrag.",
					type: "error",
				});
				Logger.error("Job is not set");
				return;
			}
			const jobTpTier = job.tp_tier;
			const jobTpValue = job.tp_value;
			const jobTpVariation = job.tp_variation;
			if (jobTpTier === null) {
				showNotification({
					message: "Dem Auftrag fehlt die Taxpunktstufe.",
					type: "error",
				});
				return;
			}
			if (jobTpValue === null) {
				showNotification({
					message: "Dem Auftrag fehlt der Taxpunktwert.",
					type: "error",
				});
				return;
			}

			const formattedItem =
				itemType === "tariff"
					? formatTariff(
							item as ExtendedTariffEntityType,
							jobDocumentId,
							clientId as string,
							get().job?.guarantor_id?.toString() ?? null,
							jobTpTier,
							jobTpValue,
							jobTpVariation ?? 0
						)
					: formatArticle(
							item as ExtendedArticleEntityType,
							jobDocumentId
						);
			Logger.info(`[addItem] formattedItem:`, formattedItem);

			if (!formattedItem) {
				showNotification({
					message:
						"Position kann nicht hinzugefügt werden. Bei dieser Position fehlen Stammdaten.",
					type: "error",
				});
				return;
			}

			set(
				produce((state) => {
					if (!state.jobItemsForDocuments[jobDocumentId]) {
						state.jobItemsForDocuments[jobDocumentId] = [];
					}
					state.jobItemsForDocuments[jobDocumentId].push(
						formattedItem
					);
				})
			);
		}

		get().handleTimer(jobDocumentId, itemType);
		get().pendingChangesNotification();
	},
	handleTimer: async (
		jobDocumentId: number,
		itemType: "tariff" | "article" | "group"
	) => {
		const timer = get().timers[jobDocumentId];
		if (timer) clearTimeout(timer);

		const newTimer = setTimeout(async () => {
			Logger.info(`Adding ${itemType} to database`);
			get().upsertJobItems(jobDocumentId, `Error adding ${itemType}`);
			set(
				produce((state) => {
					delete state.timers[jobDocumentId];
				})
			);
		}, 3000);

		set(
			produce((state) => {
				state.timers[jobDocumentId] = newTimer;
			})
		);
	},

	addTariff: async (
		code: string,
		jobDocumentId: number,
		options?: { force: boolean }
	) => {
		get().addItem(code, jobDocumentId, "tariff", options);
	},

	addArticle: async (
		code: string,
		jobDocumentId: number,
		options?: { force: boolean }
	) => {
		get().addItem(code, jobDocumentId, "article", options);
	},

	deleteJobItems: async (jobItemIds: string[], jobDocumentId: number) => {
		const jobItemsForDocument = get().jobItemsForDocuments[jobDocumentId];
		const updatedJobItemsForDocument = jobItemsForDocument.filter(
			(item) => !jobItemIds.includes(item.id)
		);
		set(
			produce((state) => {
				state.jobItemsForDocuments[jobDocumentId] =
					updatedJobItemsForDocument;
			})
		);

		const { error } = await supabase
			.from(SupabaseTableEnum.JOB_ITEMS)
			.delete()
			.in("id", jobItemIds);

		if (error) {
			showNotification({
				message: "Fehler beim Löschen der Positionen",
				type: "error",
			});
			Logger.error(error);
			return;
		}

		showNotification({
			message: "Gelöscht",
			type: "success",
		});
	},

	pasteJobItems: async (
		jobItems: JobItemEntityType[],
		jobDocumentId: number
	) => {
		const newJobItems = jobItems.map((row) => {
			const { id, modified_at, created_at, fpo_id, ...filteredRow } = row;

			return {
				...filteredRow,
				id: uuidv4(),
				price: isNaN(filteredRow.price as number)
					? 0
					: filteredRow.price,
				job_document_id: jobDocumentId,
				discount: (filteredRow.discount as number) ?? 0,
			};
		});

		set(
			produce((state) => {
				if (!state.jobItemsForDocuments[jobDocumentId]) {
					state.jobItemsForDocuments[jobDocumentId] = [];
				}
				state.jobItemsForDocuments[jobDocumentId].push(...newJobItems);
			})
		);

		get().upsertJobItems(jobDocumentId, "Error pasting job items");
		get().pendingChangesNotification();
	},

	changeJobItem: async (jobItem: any) => {
		const {
			SELECTED_FIELD,
			interpr_de,
			interpr_fr,
			interpr_it,
			is_new,
			custom,
			expanded,
			inEdit,
			selected,
			bpo_id,
			fpo_id,
			...filteredRow
		} = jobItem;

		// Store the current state for rollback
		const previousJobItems = [
			...get().jobItemsForDocuments[jobItem.job_document_id],
		];
		let error = false;

		const previousCode = previousJobItems.find(
			(item) => item.id === filteredRow.id
		)?.code;

		if (!previousCode) {
			showNotification({
				message: "Fehler beim Ändern der Position (ID nicht gefunden)",
				type: "error",
			});
			return;
		}

		Logger.info(
			`[changeJobItem] previousCode: ${previousCode}, newCode: ${filteredRow.code}, newCode_e (old): ${filteredRow.code_e}`
		);

		const newCode = filteredRow.code;

		// Check if the code has changed
		if (previousCode !== newCode) {
			const { error: changeError, message: changeErrorMessage } =
				await get().changeTariffInRow(
					newCode,
					jobItem,
					filteredRow.type === JobItemTypeEnum.TARIFF
						? "tariff"
						: "article"
				);
			if (changeError) {
				Logger.error(
					`changeError: ${changeError} ${changeErrorMessage}`
				);
				error = true;
			}
		} else {
			const newJobItems = previousJobItems.map(
				(row: JobItemEntityType) =>
					row.id === filteredRow.id ? filteredRow : row
			);

			set(
				produce((state) => {
					state.jobItemsForDocuments[jobItem.job_document_id] =
						newJobItems;
				})
			);

			const { error: changeError } = await supabase
				.from(SupabaseTableEnum.JOB_ITEMS)
				.upsert([filteredRow], {
					onConflict: "id",
					defaultToNull: false,
				});

			if (changeError) {
				error = true;
				showNotification({
					message: "Fehler beim Ändern der Position",
					type: "error",
				});
			}
		}

		if (error) {
			// Rollback to previous state
			set(
				produce((state) => {
					state.jobItemsForDocuments[jobItem.job_document_id] =
						previousJobItems;
				})
			);

			Logger.error(error);
			return;
		}
	},
	updateJobItems: async (jobId: number, tp_value: number) => {
		const { data, error: jobDocumentError } = await supabase
			.from(SupabaseTableEnum.JOB_DOCUMENTS)
			.select("id")
			.eq("job_id", jobId)
			.eq("status", JobStatusEnum.IN_PROGRESS);

		if (jobDocumentError || !data) {
			Logger.error(jobDocumentError);
			showNotification({
				message: "Fehler beim Aktualisieren des Auftrags",
				type: "error",
			});
			return;
		}

		// Optimistic update
		for (const item of data) {
			const jobDocumentId = item.id;
			const currentJobItems = get().jobItemsForDocuments[jobDocumentId];

			if (!currentJobItems) continue;

			const updatedJobItems = currentJobItems.map((jobItem) => ({
				...jobItem,
				...(tp_value !== undefined &&
					jobItem.type === JobItemTypeEnum.TARIFF && {
						tp_value: tp_value,
					}),
			}));

			// Update local state
			set(
				produce((state) => {
					state.jobItemsForDocuments[jobDocumentId] = updatedJobItems;
				})
			);

			// Server update
			const { error: errorTariff } = await supabase
				.from(SupabaseTableEnum.JOB_ITEMS)
				.update({
					...(tp_value !== undefined && {
						tp_value: tp_value,
					}),
				})
				.eq("job_document_id", jobDocumentId)
				.eq("type", JobItemTypeEnum.TARIFF);

			if (errorTariff) {
				showNotification({
					message: "Fehler beim Aktualisieren des Auftrags",
					type: "error",
				});
				Logger.error(errorTariff);

				// Revert optimistic update
				await get().fetchJobItems(jobDocumentId);
				return;
			}
		}
	},
	changeTariffInRow: async (
		code: string,
		jobItem: JobItemEntityType,
		type: "article" | "tariff"
	) => {
		const jobItems = get().jobItemsForDocuments[jobItem.job_document_id];
		const job = get().job;

		if (!job) {
			showNotification({
				message: "Es wurde kein Auftrag gefunden",
				type: "error",
			});
			return { error: true, message: "Es wurde kein Auftrag gefunden" };
		}

		const jobTpTier = job?.tp_tier;
		const jobTpValue = job?.tp_value;
		const jobTpVariation = job?.tp_variation;

		if (!jobTpTier || !jobTpValue) {
			showNotification({
				message: "Es fehlen Stammdaten",
				type: "error",
			});
			return { error: true, message: "Es fehlen Stammdaten" };
		}

		const newJobItem =
			type === "article"
				? useCentralStore.getState().articles[code]
				: useCentralStore.getState().tariffs[code];

		if (!newJobItem) {
			showNotification({
				message: "Position konnte nicht gefunden werden",
				type: "error",
			});
			return {
				error: true,
				message: "Position konnte nicht gefunden werden",
			};
		}

		const newFormattedJobItem =
			type === "article"
				? formatArticle(
						newJobItem as ExtendedArticleEntityType,
						jobItem.job_document_id
					)
				: formatTariff(
						newJobItem as ExtendedTariffEntityType,
						jobItem.job_document_id,
						useCentralStore.getState().clientId as string,
						get().job?.guarantor_id?.toString() ?? null,
						jobTpTier,
						jobTpValue,
						jobTpVariation ?? 0
					);

		const changedJobItem = {
			...newFormattedJobItem,
			techn_perc_2: jobItem?.techn_perc_2,
			techn_perc_3: jobItem?.techn_perc_3,
			profile_id: jobItem?.profile_id,
			profile2_id: jobItem?.profile2_id,
			profile3_id: jobItem?.profile3_id,
			id: jobItem.id,
			job_document_id: jobItem.job_document_id,
			quantity: jobItem.quantity,
		};

		const newJobItems = jobItems.map((item, i) =>
			item.id === jobItem.id ? changedJobItem : item
		);

		set(
			produce((state) => {
				state.jobItemsForDocuments[jobItem.job_document_id] =
					newJobItems;
			})
		);

		get().upsertJobItems(
			jobItem.job_document_id,
			"Error changing position"
		);
		get().pendingChangesNotification();

		return { error: false, message: "Position erfolgreich geändert" };
	},
});
